// Copyright The OpenTelemetry Authors
// SPDX-License-Identifier: Apache-2.0

import { useMemo, createContext, useContext } from 'react';
import { useQuery } from '@tanstack/react-query';
import ApiGateway from '../gateways/Api.gateway';
import { Flag } from '../protos/demo';

interface IContext {
  loaded: boolean;
  flags: Map<string, Flag>;
}

export const Context = createContext<IContext>({
  loaded: false,
  flags: new Map<string, Flag>(),
});

interface IProps {
  children: React.ReactNode;
}

export const useFlags = () => useContext(Context);

const FeatureFlagProvider = ({ children }: IProps) => {

  const { status, data: flagList = [] } = useQuery({
    queryKey: ['featureflags'],
    queryFn: ApiGateway.listFlags,
    retry: false
  });

  //note that 'error' status is considered 'loaded', as theflag service may be unavailable
  const loaded = status != 'pending'; 
  const flags = new Map<string, Flag>();
  flagList.forEach(flag => {
    flags.set(flag.name, flag);
  })

  const value = useMemo(
    () => ({ loaded, flags }),[loaded, flags]
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export default FeatureFlagProvider;
